<script lang="ts" setup>
import { useRouter } from 'vue-router';

import { useNuxtApp } from '#app';

import Header from '~/components/layouts/default/Header.vue';
import type { MenuItem } from '~/components/layouts/default/menu-item';
import SideMenuWrapper from '~/components/layouts/default/SideMenuWrapper.vue';

const { $device: { isMobile } } = useNuxtApp();

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const preferenceStore = usePreferenceStore();

const drawer = ref<boolean>(false);
const reveal = ref<boolean>(false);

router.afterEach(() => {
  drawer.value = false;
});

const bookingMenuItem: MenuItem = {
  label: 'Booking',
  icon: 'ic:round-event',
  name: 'booking',
  alwaysShowIcon: true,
  visible: !!preferenceStore.preference.general.enableBooking
};

const nonAuthMenu: MenuItem[] = [
  {
    label: 'Sign up',
    icon: 'ic:round-person-add',
    name: 'login-register',
    class: 'bg-company-2'
  },
  {
    label: 'Sign in',
    icon: 'ic:outline-login',
    name: 'login'
  },
  {
    label: 'Help',
    icon: 'ic:outline-help-outline',
    name: 'help',
    separator: true,
    alwaysShowIcon: true
  },
  bookingMenuItem
];

const authMenu = computed<MenuItem[]>(() => [
  bookingMenuItem,
  {
    label: 'Account',
    icon: 'ic:round-manage-accounts',
    name: 'account',
    alwaysShowIcon: true,
    defaultOpen: route.path.startsWith('/account'),
    children: [
      {
        label: 'Profile',
        icon: 'ic:round-account-box',
        name: 'account-profile'
      },
      {
        label: 'Orders',
        icon: 'ic:round-receipt',
        name: 'account-orders'
      },
      {
        label: 'Addresses',
        icon: 'ic:round-place',
        name: 'account-addresses'
      },
      {
        label: 'Cards',
        icon: 'ic:round-credit-card',
        name: 'account-cards'
      }
    ]
  },
  {
    label: 'Sign Out',
    icon: 'ic:round-logout',
    name: 'logout',
    alwaysShowIcon: true
  },
  {
    label: 'Help',
    icon: 'ic:outline-help-outline',
    name: 'help',
    alwaysShowIcon: true
  }
]);

const menu = computed<MenuItem[]>(() => authStore.isAuthenticated ? authMenu.value : nonAuthMenu);

const onGoHome = async (): Promise<void> => {
  if (preferenceStore.preference.template.homeUrl) {
    window.open(preferenceStore.preference.template.homeUrl, '_self');
    return;
  }

  await router.push({ name: 'index' });
};

const onShowDrawer = (): void => {
  drawer.value = true;
};

const updateHeaderReveal = (): void => {
  const layoutDefault = document.querySelector('.layout-default');

  if (layoutDefault!.scrollTop >= 100) {
    reveal.value = true;
  } else {
    reveal.value = false;
  }
};

onMounted(() => {
  const layoutDefault = document.querySelector('.layout-default');

  if (layoutDefault) {
    layoutDefault.addEventListener('scroll', updateHeaderReveal);
  }
});

onUnmounted(() => {
  const layoutDefault = document.querySelector('.layout-default');

  if (layoutDefault) {
    layoutDefault.removeEventListener('scroll', updateHeaderReveal);
  }
});
</script>

<template>
  <div class="layout-default absolute h-dvh w-svw overflow-y-auto">
    <slot
      name="header"
      :on-go-home="onGoHome"
      :on-show-drawer="onShowDrawer"
    >
      <Header
        :class="{ reveal }"
        :company-name="preferenceStore.preference.company.name"
        :logo-url="preferenceStore.preference.template.siteLogoUrl"
        :authenticated="authStore.isAuthenticated"
        :menu="menu"
        @click:drawer="onShowDrawer"
        @go-home="onGoHome"
      />
    </slot>

    <SideMenuWrapper
      v-if="isMobile"
      v-model="drawer"
      :menu="menu"
      data-test="drawer"
    />

    <slot />

    <slot name="footer" />
  </div>
</template>

<style lang="scss" scoped>
.layout-default {
  background-image:
    linear-gradient(to top,
      var(--bg-color-50),
      var(--bg-color-50)), var(--bg-image);
  background-color: var(--bg-color);
  background-repeat: no-repeat;
  background-size: cover;

  .header.reveal {
    transform: translateY(-110%);
    transition: all 0.3s ease-in-out;
  }
}
</style>
